import React, { useEffect, useState, useContext } from "react";
import ReactPlayer from "react-player";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";
import { Upsells } from "./Upsells";
import SundayCalendar from "./SundayCalendar";
import SundayNav from "./SundayNav";
import SundayStats from "./SundayStats";
import SundayTour from "./SundayTour";

const Home = (props) => {
  const [selectedReflection, setSelectedReflection] = useState(null);

  useEffect(() => {
    document.title = "Unstoppable Sunday";
  }, []);

  return (
    <MixpanelProvider mixpanel={mixpanel}>
      <main className="row">
        <section className="col trix-container">
          <div className="onboarding d-none">
            <h3>Welcome to Unstoppable Sunday</h3>
            <h4>
              <small>
                This web-based application gives you a set of tools to establish
                key habits and run your life at high performance every day,
                every week, and every month.
              </small>
            </h4>
            <p>
              <b>Daily Unstoppable Flow</b>
              <br />
              Get into an instant flow state with the power of the Pomodoro
              technique and Alpha Waves playing in the background.
              <br />
              <a href="/daily">Get into Flow State &rarr;</a>
            </p>
            <p>
              <b>Weekly Unstoppable Sunday</b>
              <br />
              Every Sunday, we spend 30 minutes to help you punch the Sunday
              Scaries in the face and help you go into Monday with an
              Unstoppable mindset and plan.
              <br />
              <a href="/weekly">Plan the Upcoming Week &rarr;</a>
            </p>
            <p>
              <b>Monthly Unstoppable Pause & Reflect</b>
              <br />
              In just 30 minutes, we'll help reflect on the past month,
              celebrate your wins (big or small), and help you set an intention
              for the coming month.&nbsp;
              <a href="/monthly">
                Reflect on the Past Month and Dominate the Next &rarr;
              </a>
            </p>
          </div>
          <div className="calendar p-3">
            <SundayCalendar />
          </div>
        </section>

        <section className="w-100 d-none layout-break"></section>
        <section className="col intro">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="intro_inner">
                  <SundayNav mode="" title="Unstoppable Sunday" />
                  <SundayStats user={props.user} />
                  <SundayTour mode='overview' label='Start Sunday Tour' user={props.user}/>
                  <div className="upsells">
                    <Upsells
                      trialTimestamp={props.user.subscription.trial_end_at}
                      stripeBillingUrl={props.stripe_customer_portal_url}
                      user={props.user}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </MixpanelProvider>
  );
};

export default Home;
