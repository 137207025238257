import React, { Component } from "react";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider, MixpanelConsumer } from "react-mixpanel";
import { Outlet, NavLink } from "react-router-dom";

export class SundayHeader extends Component {
  initMixpanel() {
    mixpanel.init(this.props.mixpanel_token);
    mixpanel.identify(this.props.user.id);
    mixpanel.register({
      user_id: this.props.user.id,
    });
    mixpanel.track("Loaded Sunday App");
  }

  render() {
    this.initMixpanel();
    return (
      <MixpanelProvider mixpanel={mixpanel}>
        <div>
          <Outlet />
        </div>
      </MixpanelProvider>
    );
  }
}
