import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const SundayNav = (props) => {
  const navLinks = [
    {
      title: "Overview",
      path: "/",
    },
    {
      title: "Daily",
      path: "/daily",
    },
    {
      title: "Weekly",
      path: "/weekly",
    },
    {
      title: "Monthly",
      path: "/monthly",
    },
  ];

  return (
    <div>
      <div className="d-flex flex-row mb-4">
        <div className="">
          <img
            className="logo"
            src={window.bootstrap.logo_url}
            alt="Unstoppable Sunday Logo"
          ></img>
        </div>
        <div className="">
          <p className="logo_title">Sunday</p>
        </div>
      </div>
      <nav id="sunday">
        <ul className="pagination">
          {navLinks.map((navLink, index) => {
            return (
              <li
                className={`page-item ${
                  navLink.path.replace("/", "") == props.mode ? "active" : ""
                }`}
                key={`nav-link-${index}`}
              >
                <NavLink to={navLink.path}>{navLink.title}</NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <hr />
    </div>
  );
};

export default SundayNav;
