export function saveUserMetric(data, authenticityToken, successPromise, error) {
  fetch(`${process.env.API_HOST}/user_metrics`, {
    method: 'PUT',
    headers: {
      'X-CSRF-Token': authenticityToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then( (response) => {
      if (response.status == 200) {
        return response.json();
      }
      else if (response.status == 401) {
        return error(response);
      }
    }
  ).then(data => successPromise(data));
}
