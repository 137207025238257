export function getReflections(reflectionType, successPromise, error) {
    fetch(`${process.env.API_HOST}/reflections?type=`+reflectionType)
      .then( (response) => {
        if (response.status == 401) {
          return error(response);
        }
        return response.json();
      }
    ).then(data => successPromise(data));
}

export function saveReflection(id, data, authenticityToken, successPromise, error) {
  fetch(`${process.env.API_HOST}/reflections/${id}`, {
    method: 'PUT',
    headers: {
      'X-CSRF-Token': authenticityToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then( (response) => {
      if (response.status == 200) {
        return response.json();
      }
      else if (response.status == 401) {
        return error(response);
      }
    }
  ).then(responseData => successPromise(responseData));
}

export function createReflection(data, authenticityToken, successPromise, error) {
  fetch(`${process.env.API_HOST}/reflections`, {
    method: 'POST',
    headers: {
      'X-CSRF-Token': authenticityToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then( (response) => {
      if (response.status == 200) {
        return response.json();
      }
      else if (response.status == 401) {
        return error(response);
      }
    }
  ).then(responseData => successPromise(responseData));
}
