import React, { useEffect, useState, useContext } from "react";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";
import { Upsells } from "./Upsells";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import { offset } from "@floating-ui/dom";

import "shepherd.js/dist/css/shepherd.css";

function StartTourButton(props = { label: "Start Tour" }) {
  const tour = useContext(ShepherdTourContext);

  return (
    <button className="btn btn-md btn-primary" onClick={tour.start}>
      {props.label} &rarr;
    </button>
  );
}

const SundayTour = (
  props = {
    mode: "overview",
    label: "Start Sunday Tour",
    user: { stats: { num_reflections: 0 } },
  }
) => {
  const tourOptions = {
    defaultStepOptions: {
      title: `Sunday ${props.mode} Tour`,
      cancelIcon: {
        enabled: true,
      },
      floatingUIOptions: {
        middleware: [
          offset({
            mainAxis: 10,
            crossAxis: 0,
          }),
        ],
      },
    },
    useModalOverlay: true,
  };

  const steps = {
    overview: [
      {
        text: `Unstoppable Sunday is a set of tools to help you establish key habits
      and run your life at high performance by planning your day, week, and month.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `The Daily tool helps you set your key priorities for the day and execute on them with the power of Alpha Waves and a Pomodoro timer.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul > li:nth-child(2)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `The Weekly journaling tool helps you pause and reflect on the past week, express gratitude, celebrate your wins and go into every week with a clear plan of action.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul > li:nth-child(3)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `The Monthly tool helps you reflect on your month, celebrate your wins, and ensure you're going into the new month with a set of priorities that helps you have your best year ever.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul > li:nth-child(4)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
    ],
    daily: [
      {
        text: `The Daily tool helps you set your key priorities for the day and execute on them with the power of Alpha Waves and a Pomodoro timer.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul > li:nth-child(2)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `Alpha Waves playing in the background induce feelings of calm, increase creativity, and enhance your ability to do deep work.`,
        // Center the step on the screen
        attachTo: {
          element: ".daily-player",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `The Pomodoro Timer helps you do deep work by breaking your work into 15 minute chunks with 5 minute breaks in between.`,
        // Center the step on the screen
        attachTo: {
          element: ".pomodoro-clock",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `Every Day you can write out what you want to accomplish and then execute on it with the Pomodoro Timer and Alpha Waves. We'll keep a running log of everything you've accomplished every day as you build moment.`,
        // Center the step on the screen
        attachTo: {
          element: ".reflections-list",
          on: "top",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `At the end of the week, you can reflect on the week, celebrate your wins (big or small), and go into the following week with a plan of action by using the the Weekly Sunday tool.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul > li:nth-child(3)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
    ],
    weekly: [
      {
        text: `The Weekly tool helps you pause and reflect on the past week, express gratitude, celebrate your wins and go into every week with a clear plan of action.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul > li:nth-child(3)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `To help you reflect on your week, we'll ask you a series of questions to help you express gratitude, celebrate your wins, and identify your plan of action for the coming week.`,
        // Center the step on the screen
        attachTo: {
          element: ".reflections-list",
          on: "top",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `The guided video will help you through each of the questions and help you reflect on your week. You can pause the video at any time to reflect on your own. Make sure you hit the "UNMUTE" button so you can hear the guidance!`,
        // Center the step on the screen
        attachTo: {
          element: ".video-wrapper",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `At the end of the month, you can reflect on the month, celebrate your wins (big or small), and set your intentions for the coming month by using the the Monthly Sunday tool.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul > li:nth-child(4)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
    ],
    monthly: [
      {
        text: `The Monthly tool helps you pause and reflect on the past month, express gratitude, celebrate your wins, and go into the next month with a clear plan of action that aligns with your broader vision.`,
        attachTo: {
          element: "#sunday > ul > li:nth-child(4)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `To help you reflect on your month, we'll ask you a series of questions to help you express gratitude, celebrate your wins, and identify your plan of action for the coming month. These questions are different than your weekly questions and are designed to help you take a step back and think at a higher level.`,
        // Center the step on the screen
        attachTo: {
          element: ".reflections-list",
          on: "top",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `The guided video will help you through each of the questions and help you reflect on your month. You can pause the video at any time to reflect on your own. Make sure you hit the "UNMUTE" button so you can hear the guidance!`,
        // Center the step on the screen
        attachTo: {
          element: ".video-wrapper",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
      {
        text: `Armed with a plan for the month, you can go back into your Daily and Weekly execution so you can have your best year ever.`,
        // Center the step on the screen
        attachTo: {
          element: "#sunday > ul > li:nth-child(1)",
          on: "bottom",
        },
        buttons: [
          {
            action() {
              return this.next();
            },
            text: "Next",
          },
        ],
      },
    ],
  };

  return (
    <>
      {props.user.stats.num_reflections <= 10 ? (
        <ShepherdTour steps={steps[props.mode]} tourOptions={tourOptions}>
          <StartTourButton label={props.label}></StartTourButton>
          <br/><br/>
        </ShepherdTour>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default SundayTour;
