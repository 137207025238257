import React, { Component, Fragment } from 'react';
import { ReflectionEditor } from './ReflectionEditor'
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
import 'trix'
import mixpanel from 'mixpanel-browser';
import { Markup } from 'interweave'

export class ReflectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reflection: this.props.reflection,
      reflectionContent: this.props.reflection.content,
      activeReflectionId: this.props.activeReflectionId
    }
  }

  isActive() {
    return this.state.reflection.id === this.state.activeReflectionId;
  }

  handleClick(e){
    e.preventDefault();

    if(!this.isActive()){
      this.setState({activeReflectionId: this.state.reflection.id})
      this.props.makeActive(this.state.reflection);
    }
  }

  handleReflectionUpdated(newContent) {
    var reflection = this.state.reflection;
    reflection.content = newContent;
    this.setState({reflection: reflection, reflectionContent: newContent});
  }

  renderEditMode() {
    return (
      <div data-id={this.state.reflection.id} className='reflection-container' data-active='true'>
        <ReflectionEditor reflectionType={this.props.reflectionType} reflection={this.state.reflection} authenticityToken={this.props.authenticityToken} onReflectionUpdated={this.handleReflectionUpdated.bind(this)} />
      </div>
    );
  }

  render() {
    if(this.isActive()){
      return this.renderEditMode();
    }

    return (
      <div data-id={this.state.reflection.id} className='reflection-container'>
        <div className='edit-reflection-button' onClick={this.handleClick.bind(this)}>
          Edit
        </div>
        <div className='reflection-content'>
          <Markup content={this.state.reflection.content} />
        </div>
      </div>
    );
  }
}
