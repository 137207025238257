import React, { Component, Fragment } from 'react';
import mixpanel from 'mixpanel-browser';
import { HabitSettingTimingComponent } from 'components/HabitSettingTimingComponent';
import { saveHabits } from '../api/HabitsApi';

export class HabitSettingComponent extends Component {
  constructor(props) {
    super(props);
    this.initializeState();
    this.handleInputChanged = this.handleInputChanged.bind(this);
  }

  initializeState() {
    let settingDay = '';
    let settingTime = '';
    let startDate = new Date();
    let endDate;
    // If this has
    if (this.props.user.reminder_hour){
      startDate = new Date( Date.UTC(startDate.getFullYear(), startDate.getUTCMonth(), this.props.user.reminder_wday, this.props.user.reminder_hour, 0, 0, 0) );
      // const day = startDate.toLocaleDateString([], { weekday: 'long' }).toLowerCase()
      const day = startDate.getDay();
      // Next, get the 12-hour time (9am, 2pm) - need to remove whitespace and downcase
      // const time = startDate.toLocaleTimeString([], { hour12: true, hour: 'numeric' }).replace(/ /g, '').toLowerCase();
      const time = startDate.getHours();
      settingDay = day;
      settingTime = time;
    }
    endDate = new Date(startDate);
    endDate.setHours((endDate.getHours() + 1) % 24);
    this.state = {
      eventStartTime: startDate,
      eventEndTime: endDate,
      settingDay: settingDay,
      settingTime: settingTime,
      smsEnabled: this.props.user.sms_notifications_enabled,
      wdayUTC: this.props.user.reminder_wday,
      hourUTC: this.props.user.reminder_hour,
      phoneNumber: this.props.user.phone
    }
  }

  componentDidMount() {
    this.setUpButtonEvents();
  }

  handleInputChanged(e) {
    switch(e.target.name){
      case 'sms':
        this.setState({smsEnabled: e.target.checked})
    }
  }

  persistHabitReminders() {
    saveHabits(
      {
        user: {
          sms_notifications_enabled: this.state.smsEnabled,
          reminder_wday: this.state.wdayUTC,
          reminder_hour: this.state.hourUTC
        }
      },
      this.props.authenticityToken,
      (data) => {
        mixpanel.track('Set Reminder', {})
        // Intercom('trackEvent', "Set Reminder", {});
      },
      (response) => {
        if(response.status == 401) {
          return window.open(`${process.env.API_HOST}/users/sign_in`, '_self');
        }
      }
    );
  }

  handleReminderTimeChanged(value) {
    this.setHabitTimeState(value.day, value.time)
  }

  setHabitTimeState(dayToSet, hourToSet) {
    var currentDate = new Date();
    var endDate;
    const distance = (dayToSet + 7 - currentDate.getDay()) % 7; //Calculates the correct date based on day of week

    // Sets the date based on the input. First date and then hour
    currentDate.setDate(currentDate.getDate() + distance)
    currentDate.setHours(hourToSet, 0, 0)

    // Set an end date to the same value one our later
    endDate = new Date(currentDate)
    endDate.setHours(currentDate.getHours() + 1);

    this.setState({
      eventStartTime: currentDate,
      eventEndTime: endDate,
      settingDay: dayToSet,
      settingTime: hourToSet,
      wdayUTC: currentDate.getUTCDay(),
      hourUTC: currentDate.getUTCHours()
    });
  }

  setUpButtonEvents() {
    addeventatc.register('button-click', (obj) => {
      this.persistHabitReminders();
    });
  }

  renderTimeZone() {
    const date = new Date();
    const dtf = Intl.DateTimeFormat(undefined, {timeZoneName: 'long'});
    return dtf.formatToParts(date).find((part) => part.type === 'timeZoneName').value;
  }

  render() {
    let phoneOption;
    if(this.state.phoneNumber.length === 0){
      phoneOption = <span><a className='btn btn-link btn-sm' href='/users/edit'>Enter Phone Number</a></span>;
    }
    return (
      <div>
        <section className='row'>
          <div className='col-12'>
            <p className='title'>Sunday Habit</p>
          </div>
        </section>
        <section className='row'>
          <div className='col-12'>
            Unstoppable Sunday works best when you're sitting at your laptop with a proper keyboard and you carve out 15 to 30 minutes to pause, reflect and create a plan for your week.
          </div>
        </section>

        <section className='row'>
          <div className='col-12'>
            Just pick the time you'd like to commit to practicing Unstoppable Sunday and we'll help you create a calendar event.
          </div>
        </section>

        <section className='row'>
          <form onSubmit={this.handleSubmit} className='form-group'>
            <section>
              <HabitSettingTimingComponent day='0' currentSetting={ {day: this.state.settingDay, time: this.state.settingTime} } defaultTime='14' onChange={ (response) => this.handleReminderTimeChanged(response) } />
            </section>
            <section>
              <div className='form-check'>
                <label className='form-check-label'>
                  <input className='form-check-input' type='checkbox' name='sms' id='sms' checked={this.state.smsEnabled} onChange={this.handleInputChanged} />
                  Remind me via Text
                </label>
              </div>
              {phoneOption}
            </section>
            <section>
              <div title='Add to Calendar' className='addeventatc' data-dropdown-y='up'>
                Add to Calendar
                <span className='start'>{ this.state.eventStartTime.toLocaleString() }</span>
                <span className='end'>{ this.state.eventEndTime.toLocaleString() }</span>
                <span className='timezone'>{ this.renderTimeZone() }</span>
                <span className='title'>Practice Unstoppable Sunday</span>
                <span className='description'>Happy Sunday!<br /><br />It's time to practice Unstoppable Sunday!<br />Go to https://app.unstoppablesunday.com on your laptop or desktop to start practicing!</span>
                <span className='alarm_reminder'>15</span>
                <span className='recurring'>FREQ=WEEKLY;INTERVAL=1;</span>
              </div>
            </section>
          </form>
        </section>
      </div>
    );
  }
}
