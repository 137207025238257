import moment from "moment/moment";
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

const SundayStats = (props) => {
  const [numReflections, setNumReflections] = useState(
    props.user.stats.num_reflections
  );
  const [weeklyStreak, setWeeklyStreak] = useState(
    props.user.stats.weekly_streak
  );
  const [monthlyStreak, setMonthlyStreak] = useState(
    props.user.stats.monthly_streak
  );

  const summarizeStats = () => {
    if (new Date().getDate() <= 4) {
      if (monthlyStreak < 2) {
        return (
          <p className="small">
            Happy {moment().format("MMMM")}! Now is a great time to practice{" "}
            <NavLink
              className="fw-bold text-blue text-decoration-underline"
              to="/monthly"
            >
              Unstoppable Month
            </NavLink>{" "}
            so you can reflect on the past month, celebrate your wins and plot
            your next best moves.
          </p>
        );
      }
      if (monthlyStreak > 2) {
        return (
          <p className="small">
            BOOM! You've practiced{" "}
            <NavLink
              className="fw-bold text-blue text-decoration-underline"
              to="/monthly"
            >
              Unstoppable Month
            </NavLink>{" "}
            <strong>{monthlyStreak}</strong> out of the{" "}
            <strong>last 12 months</strong>. This helps you reflect on your past
            month, celebrate your wins, and plot your next best moves. Keep it
            up! Do you have a plan for {moment().format("MMMM")}?
          </p>
        );
      }
    }

    if (weeklyStreak < 2) {
      return (
        <p className="small">
          Welcome to Unstoppable Sunday. It looks like you're new and the best
          place to start is by planning out the next 7 days by following the{" "}
          <NavLink
            className="fw-bold text-blue text-decoration-underline"
            to="/weekly"
          >
            Weekly Unstoppable Sunday
          </NavLink>{" "}
          ritual.
        </p>
      );
    } else if (weeklyStreak > 2) {
      return (
        <p className="small">
          BOOM! You've practiced{" "}
          <NavLink
            className="fw-bold text-blue text-decoration-underline"
            to="/weekly"
          >
            Unstoppable Sunday
          </NavLink>{" "}
          <strong>{weeklyStreak}</strong> out of the{" "}
          <strong>last 12 weeks</strong>. This helps you pause and reflect on
          the past week and go into each new with with a plan of attack. Keep it
          up!
        </p>
      );
    }
    return (
      <p className="small">
        <strong>
          Unstoppable Sunday is a set of tools to help you establish key habits
          and run your life at high performance every day, every week, and every
          month.
        </strong>
      </p>
    );
  };

  return <div className="nux">{summarizeStats()}</div>;
};

export default SundayStats;
