import React, { Component } from "react";

export class Upsells extends Component {
  daysLeftInTrial() {
    const trialEnd = new Date(this.props.trialTimestamp);
    const oneDay = 1000 * 60 * 60 * 24;
    const today = new Date();
    return Math.ceil((trialEnd - today) / oneDay);
  }

  renderTrialingNotice() {
    if (
      this.props.user.subscription.payment_provider_plan_state === "trialing"
    ) {
      return (
        <div className="trial">
          <div>
            You have {this.daysLeftInTrial()} days left on your trial.
            <span>
              &nbsp;
              <strong>
                <a
                  href="/users/payments/new"
                  className="text-primary strong"
                >
                  Enter Your Payment Information
                </a>
              </strong>{" "}
              to continue using Unstoppable Sunday for just $29/month.
            </span>
            <hr />
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="upsells">
        <hr />
        {this.renderTrialingNotice()}
        <p className="small">
          <a href="/users/edit">My Account</a> |{" "}
          <a href={this.props.stripeBillingUrl} target="_blank">Billing</a> |{" "}
          <a href="/users/sign_out" data-method="delete">
            Log Out
          </a>{" "}
          | <strong>belief</strong> x <strong>discipline</strong> makes you
          unstoppable. Copyright © {new Date().getFullYear()} · Unstoppable
          Ventures, LLC
        </p>
      </div>
    );
  }
}
