import React, { Component } from 'react'
import Rollbar from "rollbar"
import { SundayHeader } from './SundayHeader'
import { Weekly } from './Weekly'
import { Monthly } from './Monthly'
import { Daily } from './Daily'
import Home  from './Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'trix/dist/trix.css'

class App extends Component {
  constructor(props) {
    super(props);
    if(props.rollbar_token && props.rollbar_token.length > 0) {
      this.state = {
        rollbar: new Rollbar({
          accessToken: props.rollbar_token,
          captureUncaught: true,
          captureUnhandledRejections: true,
          maxItems: 5,
          payload: {
            environment: props.env,
            person: {
              id: props.user.id
            }
          }
        })
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SundayHeader { ... this.props}/>}>
            <Route path="/" element={<Home { ... this.props}/>}></Route>
            <Route path="/weekly" element={<Weekly { ... this.props}/>}></Route>
            <Route path="daily" element={<Daily { ... this.props}/>}></Route>
            <Route path="monthly" element={<Monthly { ... this.props}/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App
