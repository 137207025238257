import React, { Component } from 'react';
import { TwitterShareButton, TwitterDMButton } from 'react-twitter-embed';

export default class FlowViral extends Component {

    constructor(props) {
      super(props);
    }

    render() {
        var day = new Date().getDay();
        if(this.props.numMinutes > 90) {
            var viralText = "I've unlocked " + this.props.numMinutes + " minutes of flow state today thanks to the Unstoppable Flow desktop app! Pomodoro timer plus alpha waves playing in the background 🙏🙌."
            var viralTextHighlight = "I've unlocked " + this.props.numMinutes + " minutes of flow state today thanks to the Unstoppable Flow desktop app!"
            return (
                <div className="flowviral">
                    <p className="tweet-preview">
                        {viralTextHighlight}
                    </p>
                    <TwitterShareButton
                        key={this.props.numMinutes}
                        url={'https://getunstoppable.com/flow-chrome'}
                        options={{ text: viralText, via: 'tawheed', size: 'large', 'show-count': true }}
                    />
                </div>
            );        
        }
        else {
            return null;
        }
    }
}
