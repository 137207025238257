import React, { Component } from "react";
import PropTypes from "prop-types";
import "trix/dist/trix.css";
import mixpanel from "mixpanel-browser";
import Rollbar from "rollbar";
import { MixpanelProvider, MixpanelConsumer } from "react-mixpanel";
import { SundayHeader } from "./SundayHeader";
import { SundayVideo } from "./SundayVideo";
import { ReflectionListComponent } from "./ReflectionListComponent";
import { Upsells } from "./Upsells";
import { Outlet, NavLink } from "react-router-dom";
import SundayNav from './SundayNav'
import SundayTour from "./SundayTour";

export class Monthly extends Component {
  render() {
    document.title = "Unstoppable Month";
    return (
      <MixpanelProvider mixpanel={mixpanel}>
        <main className="row">
          <section className="col trix-container">
            <ReflectionListComponent
              authenticityToken={this.props.authenticity_token}
              userId={this.props.user.id}
              reflectionType={"monthly"}
            />
          </section>
          <section className="w-100 d-none layout-break"></section>
          <section className="col intro">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="intro_inner">
                    <SundayNav mode="monthly" title="Monthly Reflection" />
                    <p className="small">
                      <strong>
                        In just 30 minutes, we'll help reflect on the past
                        month, celebrate your wins (big or small) and help you
                        set your biggest goals for the coming month.
                      </strong>
                    </p>

                    <SundayTour mode='monthly' label="Start Monthly Tour" user={this.props.user}/>

                    <SundayVideo
                      authenticityToken={this.props.authenticity_token}
                      reflectionType={"monthly"}
                      videoUrl={this.props.wistia_monthly_video_url}
                      nuxEnabled={this.props.user.nux_enabled}
                    />

                    <div className="upsells">
                      <Upsells
                        trialTimestamp={
                          this.props.user.subscription.trial_end_at
                        }
                        user={this.props.user}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </MixpanelProvider>
    );
  }
}
