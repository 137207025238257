import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import { getReflections } from "../api/ReflectionsApi";
import { Markup } from "interweave";

const SundayCalendar = (props) => {
  const [events, setEvents] = useState([]);
  const [reflections, setReflections] = useState([]);
  const [selectedReflection, setSelectedReflection] = useState(null);

  useEffect(() => {
    getReflections("all", (data) => {
      // Store Reflections
      setReflections(data);

      // Set up Calendar Events
      const events = data.map((reflection) => {
        return {
          reflection_type: reflection.reflection_type,
          start: reflection.created_at,
          reflection_id: reflection.id,
          get title() {
            switch (reflection.reflection_type) {
              case "weekly":
                return "📆 Weekly Reflection";
              case "daily":
                return "✅ Daily Reflection";
              case "monthly":
                return "🗓️ Monthly Reflection";
              default:
                return "Reflection";
            }
          },
          display: "list-item",
        };
      });

      setEvents(events);
    });
  }, []);

  const handleEventClick = (event) => {
    let selectedReflection = reflections.find((reflection) => {
      return reflection.id == event.event.extendedProps.reflection_id;
    });

    console.log("Selected Reflection: ", selectedReflection);

    setSelectedReflection(selectedReflection);
  };

  const renderEventContent = (eventInfo) => {
    return <span className="fw-bold px-1">{eventInfo.event.title}</span>;
  };

  return (
    <>
      <div className={`${selectedReflection == null ? "" : "d-none"}`}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          events={events}
          eventClick={handleEventClick}
          initialView="dayGridYear"
          headerToolbar={false}
          eventContent={renderEventContent}
          eventDisplay="block"
          editable={true}
          views={{
            dayGridYear: {
              type: "dayGrid",
              duration: { years: 1 },
              buttonText: "Year",
            },
          }}
        />
      </div>
      <div className={`${selectedReflection == null ? "d-none" : ""}`}>
        <div className="reflection-list">
          <div className="reflection-container">
            <div
              className="edit-reflection-button"
              onClick={() => {
                setSelectedReflection(null);
              }}
            >
              Close
            </div>
            <div className="reflection-content">
              <Markup
                content={
                  selectedReflection == null ? "" : selectedReflection.content
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SundayCalendar;
