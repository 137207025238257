import React, { Component } from 'react';
import { TrixEditor } from "react-trix";
import  'trix'
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
import { getReflections, saveReflection } from '../api/ReflectionsApi';

export class ReflectionEditor extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      reflectionId: this.props.reflection.id,
      reflectionContent: this.props.reflection.content,
      reflectionType: this.props.reflectionType,
      lastChange: null,
      lastSave: null,
      canPersist: false,
      persistenceTimer: null,
      loadedReflections: false,
      shouldPrependNewEntry: false,
      prependBecauseEmptyContent: false,
      lastSundayEntry: null,
      todayDateString: null,
      wroteText: false
    };
  }

  persistDocument() {
    saveReflection(
      this.state.reflectionId,
      {
        reflections: {content: this.state.reflectionContent },
        activity: { wrote_text: this.state.wroteText }
      },
      this.props.authenticityToken,
      (data) => {
        this.handleDocumentUpdated();
      },
      (response) => {
        if(response.status == 401) {
          return window.open(`${process.env.API_HOST}/users/sign_in`, '_self');
        }
      }
    );
  }

  handleDocumentUpdated() {
    this.props.onReflectionUpdated(this.state.reflectionContent);
  }

  handleEditorReady(editor) {
    editor.insertHTML(this.state.reflectionContent);
    console.log(this.state.reflectionContent)
    this.setState({canPersist: true})
  }

  handleChange(html, text) {
    const numWords = html.length;

    var lastCountTimeStamp = this.getStorage(`last-count-timestamp-${this.state.reflectionId}`);

    if(lastCountTimeStamp == null)
    {
      lastCountTimeStamp = Date.now();
      this.setStorage(`last-count-timestamp-${this.state.reflectionId}`, lastCountTimeStamp);
    }

    if((Date.now() - lastCountTimeStamp) / 1000 > 60)
    {
      if(numWords > 2000) {
        mixpanel.track('Practiced Sunday', { "numWords": numWords, "reflectionType": this.props.reflectionType});
        // Intercom('trackEvent', "Practiced Sunday", { "numWords": numWords});
        this.setState({wroteText: true});
      }

      if(typeof fbq !== 'undefined'){
       fbq('track', 'ViewContent');
      }
      lastCountTimeStamp = Date.now();
      this.setStorage(`last-count-timestamp-${this.state.reflectionId}`, lastCountTimeStamp);
    }
    clearTimeout(this.state.persistenceTimer);
    if (this.state.canPersist){
      this.setStorage(`sunday-updated-at-${this.state.reflectionId}`, new Date());
      this.setState({reflectionContent: html, lastChange: new Date(), persistenceTimer: setTimeout(this.persistDocument.bind(this), 1000)})
    }
  }

  getStorage(key) {
    return localStorage.getItem(`${key}-${process.env.NODE_ENV}-${this.props.userId}`);
  }

  setStorage(key, value) {
    return localStorage.setItem(`${key}-${process.env.NODE_ENV}-${this.props.userId}`, value);
  }

  render() {
    return (
      <div className={"trix-editor-div-"+this.props.reflectionType}>
        <MixpanelConsumer>
          {mixpanel => <TrixEditor className={this.props.reflectionType} mixpanel={mixpanel} onChange={this.handleChange.bind(this)} onEditorReady={this.handleEditorReady.bind(this)}/>}
        </MixpanelConsumer>
      </div>
    );
  }
}
