import React, { Component } from 'react';
import PropTypes from "prop-types";
import mixpanel from 'mixpanel-browser';
import Rollbar from "rollbar";
import { HabitSettingComponent } from 'components/HabitSettingComponent';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';

class MobileApp extends Component {
  constructor(props) {
    super(props);
    if(props.rollbar_token && props.rollbar_token.length > 0) {
      this.state = {
        rollbar: new Rollbar({
          accessToken: props.rollbar_token,
          captureUncaught: true,
          captureUnhandledRejections: true,
          maxItems: 5,
          payload: {
            environment: props.env,
            person: {
              id: props.user.id
            }
          }
        })
      }
    }
  }

  initMixpanel() {
    mixpanel.init(this.props.mixpanel_token);
    mixpanel.identify(this.props.user.id);
    mixpanel.register({
      user_id: this.props.user.id
    });
    mixpanel.track('Loaded Reminder Page', { });
    // Intercom('trackEvent', 'Loaded Reminder Page', { });
  }

  render() {
    this.initMixpanel();
    return (
      <MixpanelProvider mixpanel={mixpanel}>
        <main className='intro'>
          <HabitSettingComponent user={this.props.user} authenticityToken={this.props.authenticity_token} />
        </main>
      </MixpanelProvider>
    );
  }
}

export default MobileApp
