import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
import { saveUserMetric } from '../api/UserMetricsApi';
 
export class SundayVideo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: this.props.videoUrl,
			pip: false,
			playing: true,
			controls: false,
			light: false,
			volume: 0.8,
			muted: false,
			played: 0,
			loaded: 0,
			duration: 0,
			playbackRate: 1.0,
			loop: false,
			progressInterval: 5000,
			fastForwardMode: true,
			stage: ""
		};
	}

	playPause = () => {
		this.setState({ playing: !this.state.playing })
	}

	stop = () => {
		this.setState({ url: null, playing: false })
	}

	toggleControls = () => {
		const url = this.state.url
		this.setState({
		  controls: !this.state.controls,
		  url: null
		}, () => this.load(url))
	}

	toggleLight = () => {
		this.setState({ light: !this.state.light })
	}

	toggleLoop = () => {
		this.setState({ loop: !this.state.loop })
	}

	setVolume = e => {
		this.setState({ volume: parseFloat(e.target.value) })
	}

	toggleMuted = () => {
		this.setState({ muted: !this.state.muted })
	}

	setPlaybackRate = e => {
		this.setState({ playbackRate: parseFloat(e.target.value) })
	}

	togglePIP = () => {
		this.setState({ pip: !this.state.pip })
	}

	onPlay = () => {
		this.setState({ playing: true })
	}

	onEnablePIP = () => {
		this.setState({ pip: true })
	}

	onDisablePIP = () => {
		this.setState({ pip: false })
	}

	onPause = () => {
		this.setState({ playing: false })
	}

	onSeekMouseDown = e => {
		this.setState({ seeking: true })
	}

	onSeekChange = e => {
		this.setState({ played: parseFloat(e.target.value) })
	}

	onSeekMouseUp = e => {
		this.setState({ seeking: false })
		this.player.seekTo(parseFloat(e.target.value))
	}

	onProgress = state => {
		// We only want to update time slider if we are not currently seeking
		if (!this.state.seeking) {
		  this.setState(state)
		}

		this.persistMetrics(state.playedSeconds);
		
		if(state.playedSeconds > 375)
		{
			this.setState({fastForwardMode: false});
		}

		// Update highlighting based on stage of the video
		// TODO: Figure out how to Reacti-fy this so hipster programmers don't complain
		var newStage = "";
		if(this.props.reflectionType=='weekly') {
			if(state.playedSeconds > 60 && state.playedSeconds < 209) {
				newStage = "stage-1";
			}
			else if(state.playedSeconds > 225 && state.playedSeconds < 429)
			{
				newStage = "stage-2";
			}
			else if(state.playedSeconds > 445 && state.playedSeconds < 669)
			{
				newStage = "stage-3";
			}
			else if(state.playedSeconds > 671 && state.playedSeconds < 792)
			{
				newStage = "stage-4-1";
			}
			else if(state.playedSeconds > 792 && state.playedSeconds < 826)
			{
				newStage = "stage-4-2";
			}
			else if(state.playedSeconds > 826 && state.playedSeconds < 909)
			{
				newStage = "stage-5";
			}
			else
			{
				newStage = "";
				
			}
			if(this.state.stage != newStage) {
				this.setState({stage: newStage});
				$("trix-editor").attr("class", this.state.stage);
				mixpanel.track("Watched Sunday Video", { "playedSeconds": state.playedSeconds, "stage": newStage, "reflectionType": this.props.reflectionType});
				// Intercom('trackEvent', 'Watched Sunday Video', { "playedSeconds": state.playedSeconds, "stage": newStage});	
			}
		} 
		else if(this.props.reflectionType == 'monthly') {
			if(state.playedSeconds > 49 && state.playedSeconds < 209) {
				newStage = "stage-1";
			}
			else if(state.playedSeconds > 210 && state.playedSeconds < 360)
			{
				newStage = "stage-2";
			}
			else if(state.playedSeconds > 361 && state.playedSeconds < 570)
			{
				newStage = "stage-3";
			}
			else if(state.playedSeconds > 630 && state.playedSeconds < 715)
			{
				newStage = "stage-4";
			}
			else if(state.playedSeconds > 880 && state.playedSeconds < 990)
			{
				newStage = "stage-5";
			}
			else if(state.playedSeconds > 1000 && state.playedSeconds < 1181)
			{
				newStage = "stage-6";
			}
			else if(state.playedSeconds > 1243 && state.playedSeconds < 1301)
			{
				newStage = "stage-7";
			}
			else
			{
				newStage = "";
				
			}
			if(this.state.stage != newStage) {
				this.setState({stage: newStage});
				$("trix-editor").attr("class", this.state.stage);
				mixpanel.track("Watched Sunday Video", { "playedSeconds": state.playedSeconds, "stage": newStage, reflectionType: this.props.reflectionType});
				// Intercom('trackEvent', 'Watched Sunday Video', { "playedSeconds": state.playedSeconds, "stage": newStage});	
			}
		}
	}

	onEnded = () => {
		this.setState({ playing: this.state.loop })
	}

	onDuration = (duration) => {
		this.setState({ duration })
	}

	persistMetrics(videoTime) {
    saveUserMetric(
      {
        user_metrics: { 
        	seconds_watched: videoTime,
        	watched_video: (videoTime / this.state.duration) >= 0.8
        }
      },
      this.props.authenticityToken,
      (response) => {
        if(response.status == 401) {
          return window.open(`${process.env.API_HOST}/users/sign_in`, '_self');
        }
      }
    );
  }

	render () {
    const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip, progressInterval } = this.state;
		return (
			<div>
				<div className='video-wrapper'>
				    <MixpanelConsumer>
				      {mixpanel => <ReactPlayer
						ref={this.ref}
						className='player'
						width='100%'
						height='100%'
						url={url}
						pip={pip}
						playing={playing}
						controls={controls}
						light={light}
						loop={loop}
						playbackRate={playbackRate}
						volume={volume}
						muted={muted}
						progressInterval={progressInterval}
						onPlay={this.onPlay}
						onEnablePIP={this.onEnablePIP}
						onDisablePIP={this.onDisablePIP}
						onPause={this.onPause}
						onEnded={this.onEnded}
						onProgress={this.onProgress}
						onDuration={this.onDuration}
					/>}
				    </MixpanelConsumer>                
          </div>
          <br/>
          <div className='video-controls'>
          	<button className='btn btn-success' onClick={this.playPause}>{this.state.playing ? 'Pause' : 'Play'}</button> 
          </div>
        </div>
		);
	}
}