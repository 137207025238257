import React, { Component, Fragment } from 'react';

export class HabitSettingTimingComponent extends Component {

  constructor(props) {
    super(props);
    this.initializeState();
    this.handleInputChanged = this.handleInputChanged.bind(this);
  }

  initializeState() {
    let day, time;
    if(this.props.currentSetting) {
      day = this.props.currentSetting.day;
      time = this.props.currentSetting.time;
    }
    else
    {
      day = this.props.day;
      time = this.props.defaultTime;
    }
    this.state = {
      day: day,
      time: time
    }
  }

  dayOptions() {
    return [
      { value: '0', label: 'Sunday' },
      { value: '1', label: 'Monday' },
      { value: '2', label: 'Tuesday' },
      { value: '3', label: 'Wednesday' },
      { value: '4', label: 'Thursday' },
      { value: '5', label: 'Friday' },
      { value: '6', label: 'Saturday' },
    ];
  }

  timeOptions() {
    return [
      { value: '0', label: '12am'},
      { value: '1', label: '1am'},
      { value: '2', label: '2am'},
      { value: '3', label: '3am'},
      { value: '4', label: '4am'},
      { value: '5', label: '5am'},
      { value: '6', label: '6am'},
      { value: '7', label: '7am'},
      { value: '8', label: '8am'},
      { value: '9', label: '9am'},
      { value: '10', label: '10am'},
      { value: '11', label: '11am'},
      { value: '12', label: '12pm'},
      { value: '13', label: '1pm'},
      { value: '14', label: '2pm'},
      { value: '15', label: '3pm'},
      { value: '16', label: '4pm'},
      { value: '17', label: '5pm'},
      { value: '18', label: '6pm'},
      { value: '19', label: '7pm'},
      { value: '20', label: '8pm'},
      { value: '21', label: '9pm'},
      { value: '22', label: '10pm'},
      { value: '23', label: '11pm'}
    ];
  }

  handleInputChanged(e) {
    if(e.target.name === 'day') {
      this.setState({ day: e.target.value * 1 });
      this.props.onChange({day: e.target.value * 1, time: this.state.time});
    }
    else if(e.target.name === 'time') {
      this.setState({ time: e.target.value * 1 });
      this.props.onChange({day: this.state.day, time: e.target.value * 1, });
    }
  }

  render() {
    return (
      <div>
        <select name='day' value={this.state.day} onChange={this.handleInputChanged} >
          { this.dayOptions().map( (opt) => 
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          )};
        </select>
        <select name='time' value={this.state.time} onChange={this.handleInputChanged} >
          { this.timeOptions().map( (opt) => 
            <option key={opt.value} value={opt.value}>{opt.label}</option>
          )};
          </select>
      </div>
    );
  }
}
